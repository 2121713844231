import { DeliveryPossibility, PostageDate } from 'lib/mailing-svc-api/mailing-service-api.types'

export enum POSTAGE_POSTCARD_ORIENTATION {
  HORIZONTAL = 'Horizontal',
}

export enum POSTAGE_PROCESSING_OPTIONS {
  RUSH = 'Rush',
  STANDARD = 'Standard',
}

export enum ADDRESS_SERVICE_OPTIONS {
  CURRENT_RESIDENT = 'Current Resident',
  DISCARD = 'Discard',
  FORWARD = 'Forward',
  NOTIFY_AND_RETURN = 'Notify and Return',
}

export type DeliveryDates = PostageDate & {
  deliveryPossibility: DeliveryPossibility
}
export type AddToCartRequest = {
  mailingServicesMerchandisingSelections?: {
    Processing: string
    'Address Service Option': string
  }
  deliveryDates?: DeliveryDates
}
