import { useLogger, RecipientAddress } from '@vp/shared-capabilities-component-library/components'
import {
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Spinner,
  Typography,
} from '@vp/swan'
import { AxiosError } from 'axios'
import { RecipientAddressFormWrapper } from 'components/recipient-form/recipient-form-wrapper.component'
import { RECIPIENT_ADDRESS_FORM_ID } from 'constants/global.constant'
import { useIsInBrowser } from 'hooks/is-in-browser.hook'
import { useAddressUpsert } from 'lib/address-list'
import { ErrorBoundary, withErrorBoundary } from 'lib/errors'
import { useMsgAddressDialogHeading } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgModalClose, useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useErrorToastWithDuration } from 'lib/toast/toast.hooks'
import { useShopperId } from 'lib/user'
import { AddressFormStatus } from 'modules/confirmation/types/address.types'
import { FC, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { VpAddress } from 'types/vp-address-form.types'
import { Notifier } from 'utilities/Notifier'
import { cleanMyAddress, updateMyAddress } from '../../../../../lib/localStorage/localStorageRepository'

type AddMyAddressDialogProps = {
  formStatus: AddressFormStatus
  openAddressForm: boolean
  myAddress?: VpAddress
  setMyAddress: (val?: VpAddress) => void
  setFormStatus: (val: AddressFormStatus) => void
  setOpenAddressForm: (val: boolean) => void
}

const AddMyAddressDialogInternal: FC<AddMyAddressDialogProps> = ({
  myAddress,
  setMyAddress,
  formStatus,
  openAddressForm,
  setFormStatus,
  setOpenAddressForm,
}) => {
  const intl = useIntl()
  const loadingMessage = useMsgModalLoadingText()
  const unknownErrorToast = useToastSomethingWentWrong()
  const invalidAddressToast = useErrorToastWithDuration(intl.formatMessage({ defaultMessage: 'Address entry is invalid' }))
  const modalCloseLabel = useMsgModalClose()
  const addressDialogHeading = useMsgAddressDialogHeading()
  const isInBrowser = useIsInBrowser()
  const shopperId = useShopperId() as string
  const addressFormRef = useRef<HTMLFormElement>(null)
  const notifier = useMemo(() => new Notifier(), [])
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false)
  const { logError, logWarn } = useLogger()

  const {
    mutationResult: { mutateAsync: upsertAddress },
  } = useAddressUpsert()

  const initiateFormSubmit = () => {
    notifier.notify()
    addressFormRef.current?.submit()
  }

  const onFormUpdate = async (formData: VpAddress) => {
    setFormStatus(AddressFormStatus.SUBMIT_IN_PROGRESS)
    try {
      const data = await upsertAddress({
        request: {
          addresses: [
            {
              id: myAddress?.id,
              displayName: '',
              structuredAddress: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                middleName: formData.middleName || '',
                salutation: formData.salutation || '',
                suffix: formData.suffix || '',
                title: formData.title || '',
                additionalStreetInfo: formData.additionalStreetInfo,
                streetName: formData.streetName,
                city: formData.city,
                company: formData.company,
                country: formData.country,
                postalCode: formData.postalCode,
                state: formData.state,
              },
            },
          ],
        },
      })

      const newAddress = { ...formData, id: data.addressIds[0] }

      setMyAddress(newAddress)
      updateMyAddress({ addressData: newAddress, isInBrowser, shopperId, logWarn })
      setFormStatus(AddressFormStatus.SUBMITTED)
      setOpenAddressForm(false)
    } catch (e) {
      const err = e as AxiosError
      logError('Error occurred while creating a new address', { contextData: { e } })
      setFormStatus(AddressFormStatus.INITIAL)
      if (err?.response?.data?.invalidAddresses?.length) {
        invalidAddressToast()
      } else {
        unknownErrorToast()
      }
    }
  }

  const onRemoveAddress = () => {
    setMyAddress(undefined)
    cleanMyAddress({ isInBrowser, shopperId, logWarn })
    setOpenAddressForm(false)
    setFormStatus(AddressFormStatus.INITIAL)
  }

  const recipientAddressFormWrapperProps = {
    address: myAddress,
    onSubmitForm: (validatedAddress: RecipientAddress) => {
      const transformedAddress = {
        ...myAddress,
        ...validatedAddress,
        lastName: validatedAddress.recipient as string,
      } as VpAddress

      onFormUpdate(transformedAddress)
      return Promise.resolve(true)
    },
    setSubmitButtonDisabled,
    onReset: onRemoveAddress,
  }

  return (
    <ModalDialog
      bodyWidth="capped"
      variant="panel-right"
      isOpen={openAddressForm}
      onRequestDismiss={() => {
        setOpenAddressForm(false)
      }}
    >
      <ModalDialogContent aria-labelledby={addressDialogHeading}>
        <ModalDialogNav>
          <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
        </ModalDialogNav>
        <ModalDialogHeader>
          <ModalDialogTitle>
            <FormattedMessage defaultMessage="Where do you want to send your postcard?" />
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <RecipientAddressFormWrapper {...recipientAddressFormWrapperProps}></RecipientAddressFormWrapper>
        </ModalDialogBody>
        <ModalDialogFooter pinned>
          <ErrorBoundary>
            <Button
              skin="primary"
              m={0}
              width="full-width"
              type="submit"
              onClick={initiateFormSubmit}
              disabled={submitButtonDisabled}
              form={RECIPIENT_ADDRESS_FORM_ID}
            >
              <FormattedMessage defaultMessage="Save" description="Confirm address form submission" />
              {formStatus === AddressFormStatus.SUBMIT_IN_PROGRESS && <Spinner ml={2} mr={0} accessibleText={loadingMessage} />}
            </Button>
          </ErrorBoundary>
          {formStatus === AddressFormStatus.SUBMITTED && (
            <Typography mt={3} mb={2} fontSize={'standard'} textAlign="center">
              <ErrorBoundary>
                <Button skin="unstyled" style={{ textDecoration: 'underline' }} type="reset" form={RECIPIENT_ADDRESS_FORM_ID}>
                  <FormattedMessage defaultMessage="Remove my address" description="Button label to delete the address" />
                </Button>
              </ErrorBoundary>
            </Typography>
          )}
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export const AddMyAddressDialog = withErrorBoundary(AddMyAddressDialogInternal)
