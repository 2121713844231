import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { useMsgSeoDescriptionConfirmationPage, useMsgSeoTitleConfirmationPage } from 'lib/intl/msg-seo-info.hooks'
import { FC } from 'react'
import { ConfirmationContainer } from '../components/confirmation-container/confirmation-container.component'
import { ConfirmationContextProvider } from '../contexts/confirmation.context'

export const ConfirmationPage: FC<unknown> = () => {
  const seoTitleConfirmationPage = useMsgSeoTitleConfirmationPage()
  const seoDescriptionConfirmationPage = useMsgSeoDescriptionConfirmationPage()
  return (
    <>
      <PageSeoTitle content={seoTitleConfirmationPage} />
      <PageSeoDescription content={seoDescriptionConfirmationPage} />
      <ConfirmationContextProvider>
        <ConfirmationContainer />
      </ConfirmationContextProvider>
    </>
  )
}
