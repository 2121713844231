import {
  Box,
  Button,
  FlexBox,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Typography,
} from '@vp/swan'
import { ShimmerTablet } from 'components/loader/shimmer/shimmer.component'
import { withErrorBoundary } from 'lib/errors'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { useMsgPostageOptionScheduleDateLabel } from 'lib/intl/msg-postage-options.hooks'
import { TransformedDeliveryPossibilitiesResponse, TransformedPostageDate } from 'lib/mailing-svc-api/mailing-service-api.types'
import {
  ATTRIBUTE_KEYS,
  ConfigurationAttributeOption,
  PriceDisplay,
  PriceDisplayProps,
  useProductInfoConfigAttribute,
  useProductInfoPricingSummary,
  useProductInfoQtySelection,
  useProductInfoQuery,
} from 'lib/product-info'
import { FC, useEffect, useMemo, useState } from 'react'
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl'
import { getConfiguratorAttribute } from 'utilities/attributes.utils'
import classes from './processing-options.module.scss'
import { transformedProcessingOptions } from './processing-utils'
import { Scheduler } from './scheduler.component'

type scheduleDeliveryPerProcessingOption = { [key: string]: TransformedPostageDate | null }

type ProcessingOptionInternalProps = {
  processingOptionConfig: ConfigurationAttributeOption
  isFetchingDeliveryDetails: boolean
  isLoadingDeliveryDetails: boolean
  deliveryDetails?: TransformedDeliveryPossibilitiesResponse
  userSelection: Record<string, string>
  onProcessingOptionScheduleDeliveryChange: (
    newTransformedPostageDate: TransformedPostageDate | null,
    fromProcessingOption: string,
    fromCancelCurrentSchedule?: boolean,
  ) => void
  setScheduleDeliveryPerProcessingOption: (f: (oldValue: scheduleDeliveryPerProcessingOption) => scheduleDeliveryPerProcessingOption) => void
}

const ProcessingOptionInternal: FC<ProcessingOptionInternalProps> = ({
  processingOptionConfig,
  isFetchingDeliveryDetails,
  isLoadingDeliveryDetails,
  deliveryDetails,
  userSelection,
  onProcessingOptionScheduleDeliveryChange,
  setScheduleDeliveryPerProcessingOption,
}) => {
  const [showScheduler, setShowScheduler] = useState<boolean>(false)
  const [hasBeenRendered, setHasBeenRendered] = useState<boolean>(false)
  const [selectedDeliverySchedule, setSelectedDeliverySchedule] = useState<TransformedPostageDate | null>(null)
  const [schedulerRange, setSchedulerRange] = useState<TransformedPostageDate | null>(null)
  const [shouldAutoSelectDeliverySchedule, setShouldAutoSelectDeliverySchedule] = useState<boolean>(true)
  const attributes = useProductInfoConfigAttribute()
  const postageOptionScheduleDateLabel = useMsgPostageOptionScheduleDateLabel()
  const processingOptions = transformedProcessingOptions(getConfiguratorAttribute(ATTRIBUTE_KEYS.PROCESSING, attributes))
  const modalCloseLabel = useMsgModalClose()

  const selectedAttributes = useMemo(() => {
    return {
      ...(userSelection || {}),
      [ATTRIBUTE_KEYS.PROCESSING]: processingOptionConfig.key,
    }
  }, [userSelection, processingOptionConfig.key])
  const { selectedQty } = useProductInfoQtySelection()
  const priceData = useProductInfoPricingSummary()
  const loadFromParent = !!userSelection && userSelection[ATTRIBUTE_KEYS.PROCESSING] === processingOptionConfig.key
  const { data: productInfoResponse } = useProductInfoQuery(loadFromParent ? {} : undefined, selectedAttributes, selectedQty, [
    `${ATTRIBUTE_KEYS.PROCESSING}:${processingOptionConfig.key}`,
  ])

  const displayPrice = useMemo(() => {
    const price = loadFromParent ? priceData : productInfoResponse?.standardConfigurator?.price
    const attributeProcessingPrice = price?.breakdown?.find(
      breakdown => breakdown.name === ATTRIBUTE_KEYS.PROCESSING && breakdown.value === processingOptionConfig.key,
    )
    const attributePostagePrice = price?.breakdown?.find(breakdown => breakdown.name === ATTRIBUTE_KEYS.POSTAGE)
    const priceProps: PriceDisplayProps | null =
      price && attributeProcessingPrice
        ? {
            currency: price.currency,
            fractionDigits: price.fractionDigits,
            values: {
              ...attributeProcessingPrice,
              listPrice: attributeProcessingPrice.listPrice + (attributePostagePrice?.listPrice || 0),
              discountPrice: attributeProcessingPrice.discountPrice + (attributePostagePrice?.discountPrice || 0),
            },
          }
        : null
    return priceProps
  }, [productInfoResponse, priceData, loadFromParent, processingOptionConfig.key])

  const scheduleDelivery = () => {
    onProcessingOptionScheduleDeliveryChange(schedulerRange, processingOptionConfig.key)
    setScheduleDeliveryPerProcessingOption((oldValue: scheduleDeliveryPerProcessingOption) => ({
      ...oldValue,
      [processingOptionConfig.key]: schedulerRange,
    }))
    setSelectedDeliverySchedule(schedulerRange)
    setShowScheduler(false)
  }

  useEffect(() => {
    if (shouldAutoSelectDeliverySchedule && deliveryDetails !== undefined) {
      setShouldAutoSelectDeliverySchedule(false)
      const firstKey = Object.keys(deliveryDetails.deliveryOptions[processingOptionConfig.key].deliverySchedule)[0]
      const newDeliveryDate = deliveryDetails.deliveryOptions[processingOptionConfig.key].deliverySchedule[firstKey]
      setSelectedDeliverySchedule(newDeliveryDate)
      setScheduleDeliveryPerProcessingOption((oldValue: scheduleDeliveryPerProcessingOption) => ({
        ...oldValue,
        [processingOptionConfig.key]: newDeliveryDate,
      }))
    }
  }, [
    shouldAutoSelectDeliverySchedule,
    deliveryDetails,
    setScheduleDeliveryPerProcessingOption,
    onProcessingOptionScheduleDeliveryChange,
    processingOptionConfig.key,
  ])

  useEffect(() => {
    setHasBeenRendered(showScheduler)
  }, [showScheduler])

  return (
    <>
      <FlexBox>
        <Box
          className="flex-1-1"
          onClick={() => {
            setSelectedDeliverySchedule(selectedDeliverySchedule)
            setSchedulerRange(selectedDeliverySchedule)
          }}
        >
          <Typography fontSkin="body-standard-bold">{processingOptionConfig.name}</Typography>
          {isLoadingDeliveryDetails && <ShimmerTablet width={40} size="small" my={1} />}
          {!!selectedDeliverySchedule && (
            <Typography fontSkin="body-small">
              <FormattedMessage
                defaultMessage="Arrives {dateRange}"
                description="New delivery schedule range"
                values={{
                  dateRange: (
                    <FormattedDateTimeRange
                      dateStyle="long"
                      from={new Date(selectedDeliverySchedule.earliestArrivalDate)}
                      to={new Date(selectedDeliverySchedule.latestArrivalDate)}
                    />
                  ),
                }}
              />
            </Typography>
          )}
          <Link
            render={p => (
              <Button
                skin="link"
                className={p.className}
                aria-disabled={!processingOptions?.length}
                onClick={() => {
                  setShowScheduler(true)
                }}
              >
                {p.children}
              </Button>
            )}
          >
            <Typography fontSkin="body-small">
              {!!selectedDeliverySchedule && (
                <FormattedMessage defaultMessage="Edit the delivery dates" description="Edit the selected date for delivering the postcard" />
              )}
              {!selectedDeliverySchedule && (
                <FormattedMessage defaultMessage="Select the delivery dates" description="Select a date for delivering the postcard" />
              )}
            </Typography>
          </Link>
          <Typography fontSkin="body-small" textColor="subtle">
            <PriceDisplay displayType="plusOrMinus" {...displayPrice} />
          </Typography>
        </Box>

        <ModalDialog
          variant="panel-right"
          bodyWidth="capped"
          isOpen={showScheduler}
          onRequestDismiss={() => {
            setShowScheduler(false)
          }}
        >
          <ModalDialogContent aria-labelledby={postageOptionScheduleDateLabel}>
            <ModalDialogNav>
              <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
            </ModalDialogNav>
            <ModalDialogHeader>
              <ModalDialogTitle>
                <FormattedMessage defaultMessage="Schedule a date" description="Schedule a date for delivering the postcard" />
              </ModalDialogTitle>
            </ModalDialogHeader>
            <ModalDialogBody>
              <Scheduler
                isFetchingDeliveryDetails={isFetchingDeliveryDetails}
                deliveryDetails={deliveryDetails}
                selectedDeliverySchedule={selectedDeliverySchedule}
                setSelectedDeliverySchedule={setSchedulerRange}
                processingOption={processingOptionConfig.key}
                isContainerRendered={hasBeenRendered}
              />
            </ModalDialogBody>
            <ModalDialogFooter pinned>
              <ModalDialogButtons className={classes.buttons}>
                <Button
                  disabled={!selectedDeliverySchedule}
                  width="full-width"
                  style={{ width: '-webkit-fill-available' }}
                  skin="primary"
                  onClick={scheduleDelivery}
                >
                  <FormattedMessage defaultMessage="Confirm" description="Confirm date range selection for postage delivery" />
                </Button>
              </ModalDialogButtons>
            </ModalDialogFooter>
          </ModalDialogContent>
        </ModalDialog>
      </FlexBox>
    </>
  )
}

export const ProcessingOption = withErrorBoundary(ProcessingOptionInternal)
