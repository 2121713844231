import { VpAddress } from '../../types/vp-address-form.types'

export type AddMyAddressStorage = {
  address: VpAddress
}

export function cleanMyAddress(params: { isInBrowser: boolean; shopperId: string; logWarn: (message: string, context: unknown) => void }) {
  const { isInBrowser, shopperId, logWarn } = params
  try {
    if (isInBrowser && !!localStorage) {
      localStorage.removeItem(getLocalStorageKey(shopperId))
    }
  } catch (e) {
    logWarn('removing my address state from localStorage failed', {})
  }
}

export function updateMyAddress(params: {
  addressData: VpAddress
  isInBrowser: boolean
  shopperId: string
  logWarn: (message: string, context: unknown) => void
}) {
  const { addressData, isInBrowser, shopperId, logWarn } = params
  try {
    if (addressData && isInBrowser && !!localStorage) {
      const data: AddMyAddressStorage = {
        address: addressData,
      }

      localStorage.setItem(getLocalStorageKey(shopperId), JSON.stringify(data))
    }
  } catch (e) {
    logWarn('persisting my address state to localStorage failed.', { contextData: { addressData } })
  }
}

export function getMyAddress(params: { isInBrowser: boolean; shopperId: string; logWarn: (message: string, context: unknown) => void }): VpAddress | undefined {
  const { isInBrowser, shopperId, logWarn } = params
  try {
    if (!isInBrowser || !localStorage) {
      return undefined
    }
    const localStorageItem = localStorage.getItem(getLocalStorageKey(shopperId))
    if (!localStorageItem) {
      return undefined
    }

    return JSON.parse(localStorageItem).address as VpAddress
  } catch (e) {
    logWarn('getting my address state from localStorage failed.', {})
    return undefined
  }
}

export function getMyAddressId(params: { isInBrowser: boolean; shopperId: string; logWarn: (message: string, context: unknown) => void }): string {
  const { isInBrowser, shopperId, logWarn } = params
  try {
    const myAddress = getMyAddress({ isInBrowser, shopperId, logWarn })
    if (!myAddress?.id) {
      return ''
    }

    return myAddress.id
  } catch (e) {
    logWarn('getting my address state from localStorage failed.', {})
    return ''
  }
}

function getLocalStorageKey(shopperId: string): string {
  const ADD_MY_ADDRESS_LS_KEY = 'add-my-address'
  return `${ADD_MY_ADDRESS_LS_KEY}-${shopperId}`
}
