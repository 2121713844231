import { createContext, FC, PropsWithChildren, useContext, useState } from 'react'

type ConfirmationContextProps = {
  showScheduler: boolean
  setShowScheduler: (val: boolean) => void
}

const ConfirmationContext = createContext<ConfirmationContextProps | undefined>(undefined)

export const ConfirmationContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [showScheduler, setShowScheduler] = useState(false)
  return <ConfirmationContext.Provider value={{ showScheduler, setShowScheduler }}>{children}</ConfirmationContext.Provider>
}

export function useConfirmation() {
  const value = useContext(ConfirmationContext)
  if (!value) {
    throw new Error('useConfirmation should be used only inside ConfirmationContextProvider')
  }
  return value
}
