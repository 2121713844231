import { Icon, Span, Typography } from '@vp/swan'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import classes from './review-recipients.module.scss'

export const ReviewRecipients: FC<{ numberOfAddresses?: number; isMaiLingList: boolean }> = ({ numberOfAddresses = 0, isMaiLingList }) => {
  return (
    <Typography fontSkin="body-standard" mb="4">
      <Span className={classes.checkMark} mr={2}>
        <Icon iconType="success" size="20p" skin="success" />
      </Span>
      {numberOfAddresses === 1 ? (
        <FormattedMessage
          defaultMessage="You've uploaded {addressCount} valid address"
          description="Message is shown when user has uploaded one address e.g. You've uploaded 1 valid address"
          values={{ addressCount: numberOfAddresses }}
        />
      ) : isMaiLingList ? (
        <FormattedMessage
          defaultMessage="You've uploaded {addressCount} valid addresses"
          description="Message is shown when user has uploaded more than one addresses e.g. You've uploaded 10 valid addresses"
          values={{ addressCount: numberOfAddresses }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="You've added {addressCount} recipient addresses"
          description="Message is shown when user is purchasing an address list e.g. You are purchasing 1000 addresses"
          values={{ addressCount: numberOfAddresses }}
        />
      )}
    </Typography>
  )
}
