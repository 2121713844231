import { useAuthorizationHeader } from 'lib/user'
import { useQuery } from 'react-query'
import { failedApiPromiseForIncompleteArguments } from 'utilities/api.utility'
import { fetchPurchaseListWork } from './purchase-list.api'

export function usePurchaseListWorkEntity(purchaseListWorkId?: string | null) {
  const authorizationHeader = useAuthorizationHeader()

  return useQuery(
    ['purchase-list-work-entity', authorizationHeader, purchaseListWorkId],
    async ({ signal }) => {
      if (authorizationHeader && purchaseListWorkId) {
        return fetchPurchaseListWork({ authorizationHeader, purchaseListWorkId, signal })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!authorizationHeader && !!purchaseListWorkId,
    },
  )
}
