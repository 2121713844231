import { H2, SelectionSet, SelectionSetInput, SelectionSetLabel } from '@vp/swan'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { withErrorBoundary } from 'lib/errors'
import { TransformedPostageDate } from 'lib/mailing-svc-api/mailing-service-api.types'
import { useDeliveryPossibilities } from 'lib/mailing-svc-api/mailing-service.hooks'
import { ATTRIBUTE_KEYS, useProductInfoConfigAttribute, useProductInfoUserSelection } from 'lib/product-info'
import { useProductInfoUpdate } from 'lib/product-info/product-info.context'
import { PageName } from 'lib/telemetry'
import { POSTAGE_PROCESSING_OPTIONS } from 'modules/confirmation/types/confirmation.types'
import { FC, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getConfiguratorAttribute } from 'utilities/attributes.utils'
import { ProcessingOption } from './processing-option.component'
import { ProcessingOptionsPlaceholder } from './processing-options-placeholder/processing-options-placeholder.component'
import { transformedProcessingOptions } from './processing-utils'

type ProcessingOptionsProps = {
  recipientsCount?: number
  setConfirmedDeliverySchedule: (val: TransformedPostageDate | null) => void
}

const ProcessingOptionsInternal: FC<ProcessingOptionsProps> = ({ recipientsCount = 0, setConfirmedDeliverySchedule }) => {
  const { data: deliveryDetails, isFetching: isFetchingDeliveryDetails, isLoading: isLoadingDeliveryDetails } = useDeliveryPossibilities(recipientsCount)
  const { userSelection } = useProductInfoUserSelection()
  const updateSelection = useProductInfoUpdate()
  const attributes = useProductInfoConfigAttribute()
  const processingOptions = transformedProcessingOptions(getConfiguratorAttribute(ATTRIBUTE_KEYS.PROCESSING, attributes))
  const trackingProductPageName = useTrackingProductPageName(PageName.CONFIRMATION_PAGE)
  const [selectedProcessingOption, setSelectedProcessingOption] = useState(userSelection[ATTRIBUTE_KEYS.PROCESSING])
  const [scheduleDeliveryPerProcessingOption, setScheduleDeliveryPerProcessingOption] = useState<{ [key: string]: TransformedPostageDate | null }>({})

  const onProcessingOptionChange = useCallback(
    (key: string | null) => {
      if (key === null) return

      updateSelection({
        selections: {
          ...userSelection,
          [ATTRIBUTE_KEYS.PROCESSING]: key,
        },
      })

      setSelectedProcessingOption(key)

      if (scheduleDeliveryPerProcessingOption[key] !== undefined) {
        setConfirmedDeliverySchedule(scheduleDeliveryPerProcessingOption[key])
      } else {
        setConfirmedDeliverySchedule(null)
      }
    },
    [scheduleDeliveryPerProcessingOption, setConfirmedDeliverySchedule, trackingProductPageName, updateSelection, userSelection],
  )

  const onProcessingOptionScheduleDeliveryChangeHandler = useCallback(
    (newTransformedPostageDate: TransformedPostageDate | null, fromProcessingOption: string) => {
      if (fromProcessingOption === selectedProcessingOption) {
        setConfirmedDeliverySchedule(newTransformedPostageDate)
      }
    },
    [selectedProcessingOption, setConfirmedDeliverySchedule],
  )

  useEffect(() => {
    if (userSelection !== undefined && Object.keys(userSelection).length > 0 && selectedProcessingOption === undefined && deliveryDetails !== undefined) {
      onProcessingOptionChange(POSTAGE_PROCESSING_OPTIONS.STANDARD)
      const firstKey = Object.keys(deliveryDetails.deliveryOptions[POSTAGE_PROCESSING_OPTIONS.STANDARD].deliverySchedule)[0]
      const newDeliveryDate = deliveryDetails.deliveryOptions[POSTAGE_PROCESSING_OPTIONS.STANDARD].deliverySchedule[firstKey]
      setConfirmedDeliverySchedule(newDeliveryDate)
    }
  }, [userSelection, deliveryDetails, onProcessingOptionChange, selectedProcessingOption, setConfirmedDeliverySchedule])

  return (
    <>
      <H2 fontSkin="title-subsection">
        <FormattedMessage defaultMessage="Postage" description="Postage heading for the postage processing selection" />
      </H2>
      {!processingOptions && <ProcessingOptionsPlaceholder />}
      {!!processingOptions && (
        <SelectionSet variant="single-select" selectedValue={selectedProcessingOption} onSelectedValueChange={onProcessingOptionChange}>
          {processingOptions?.map((u, idx) => (
            <SelectionSetInput key={u.key} value={u.key} disabled={!u.isCompatible}>
              <SelectionSetLabel backgroundColor="standard" mb={calculateMarginBottom(idx)}>
                <ProcessingOption
                  processingOptionConfig={u}
                  deliveryDetails={deliveryDetails}
                  isFetchingDeliveryDetails={isFetchingDeliveryDetails}
                  isLoadingDeliveryDetails={isLoadingDeliveryDetails}
                  userSelection={userSelection}
                  onProcessingOptionScheduleDeliveryChange={onProcessingOptionScheduleDeliveryChangeHandler}
                  setScheduleDeliveryPerProcessingOption={setScheduleDeliveryPerProcessingOption}
                />
              </SelectionSetLabel>
            </SelectionSetInput>
          ))}
        </SelectionSet>
      )}
    </>
  )
}

const calculateMarginBottom = (idx: number) => {
  return idx === 0 ? '4' : 'between-subsections'
}

export const ProcessingOptions = withErrorBoundary(ProcessingOptionsInternal)
