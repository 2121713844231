import axios, { AxiosInstance } from 'axios'
import { ADDRESS_MANAGEMENT_PURCHASE_BASE, MSP_REQUESTER } from 'constants/env.constants'
import { AuthProps } from 'types/api.types'
import { PurchaseListWokEntityMetadata } from 'types/purchase-list.types'

export const addressManagementPurchaseApiClient: AxiosInstance = axios.create({
  baseURL: ADDRESS_MANAGEMENT_PURCHASE_BASE,
})

export async function fetchPurchaseListWork({
  purchaseListWorkId,
  signal,
  authorizationHeader,
}: AuthProps & { purchaseListWorkId: string }): Promise<PurchaseListWokEntityMetadata> {
  const response = await addressManagementPurchaseApiClient.get<PurchaseListWokEntityMetadata>(
    `/v1/purchase-list-work-entity/${purchaseListWorkId}/purchase-list-metadata?requestor=${MSP_REQUESTER}`,
    {
      headers: {
        Authorization: authorizationHeader,
      },
      signal,
    },
  )

  return response.data
}
