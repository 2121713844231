import { Box, Typography } from '@vp/swan'
import { SectionLoaderWrapper } from 'components/loader/loader.component'
import { format } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { useLocalization } from 'hooks/use-localization'
import { ErrorBoundary } from 'lib/errors'
import { TransformedDeliveryPossibilitiesResponse, TransformedPostageDate } from 'lib/mailing-svc-api/mailing-service-api.types'
import { FC, useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Calendar, Modifiers, ModifiersClassNames } from 'react-nice-dates'
import { areDatesEqual, findDatesBetween, isDateInRange } from '../../../../utilities/scheduler-utils'

const modifiersClassNames: ModifiersClassNames = {
  isHoliday: '-date-holiday',
  isHovered: '-date-hovered',
  isLeftEdgeInHover: '-left-edge-in-hover',
  isRightEdgeInHover: '-right-edge-in-hover',
  isSelected: '-date-selected',
  isLeftEdgeInSelect: '-left-edge-in-select',
  isRightEdgeInSelect: '-right-edge-in-select',
}

type SchedulerProps = {
  deliveryDetails?: TransformedDeliveryPossibilitiesResponse
  isFetchingDeliveryDetails: boolean
  selectedDeliverySchedule: TransformedPostageDate | null
  processingOption: string
  isContainerRendered: boolean
  setSelectedDeliverySchedule: (val: TransformedPostageDate) => void
}

export const Scheduler: FC<SchedulerProps> = ({
  deliveryDetails,
  processingOption,
  isFetchingDeliveryDetails,
  selectedDeliverySchedule,
  setSelectedDeliverySchedule,
  isContainerRendered,
}) => {
  const availableDates = deliveryDetails?.deliveryOptions[processingOption].deliverySchedule
  const selectableDates = deliveryDetails?.deliveryOptions[processingOption].selectableDates
  const { locale } = useLocalization()
  const calendarLocale = locale === 'es-US' ? es : enUS
  const [minDate, setMinDate] = useState<Date>(new Date())
  const [maxDate, setMaxDate] = useState<Date>(new Date())
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date())
  const [hoverDates, setHoverDates] = useState<Date[]>([])
  const [selectedDates, setSelectedDates] = useState<Date[]>([])
  const [isDateOutsideRange, setIsDateOutsideRange] = useState<boolean>(false)

  useEffect(() => {
    if (selectableDates?.length) {
      setMinDate(new Date(selectableDates[0]))
      setCurrentMonth(new Date(selectableDates[0]))
      setMaxDate(new Date(selectableDates[selectableDates.length - 1]))
    }
  }, [selectableDates])

  useEffect(() => {
    if (selectedDeliverySchedule && isContainerRendered) {
      setSelectedDates(findDatesBetween(selectedDeliverySchedule.earliestArrivalDate, selectedDeliverySchedule.latestArrivalDate))
    } else {
      setSelectedDates([])
    }
  }, [selectedDeliverySchedule, isContainerRendered])

  useEffect(() => {
    setIsDateOutsideRange(false)
    if (hoverDates.some(u => u > maxDate)) {
      setIsDateOutsideRange(true)
    }
  }, [hoverDates, maxDate])

  const calendarModifiers = useMemo<Modifiers>(
    () => ({
      disabled: (date: number | Date) => {
        const formattedDate = format(date, 'yyyy-MM-dd', { locale: calendarLocale })
        if (selectableDates && !selectableDates.includes(formattedDate)) {
          return true
        }
        return false
      },
      isHovered: (date: number | Date) => hoverDates.some(hoverDate => areDatesEqual(date, hoverDate)),
      isLeftEdgeInHover: (date: number | Date) => areDatesEqual(date, hoverDates[0]),
      isRightEdgeInHover: (date: number | Date) => areDatesEqual(date, hoverDates[hoverDates.length - 1]),
      isSelected: (date: number | Date) => selectedDates.some(selectedDate => areDatesEqual(selectedDate, date)),
      isLeftEdgeInSelect: (date: number | Date) => areDatesEqual(date, selectedDates[0]),
      isRightEdgeInSelect: (date: number | Date) => areDatesEqual(date, selectedDates[selectedDates.length - 1]),
      isHoliday: (date: number | Date) => {
        if (selectableDates?.length) {
          const formattedDate = format(date, 'yyyy-MM-dd', { locale: calendarLocale })
          if (hoverDates.length) {
            if (isDateInRange(date, hoverDates) && !selectableDates.includes(formattedDate)) {
              return true
            }
          }
          if (selectedDates.length) {
            if (isDateInRange(date, selectedDates) && !selectableDates.includes(formattedDate)) {
              return true
            }
          }
        }
        return false
      },
    }),
    [calendarLocale, selectableDates, hoverDates, selectedDates],
  )

  const getSchedulerRange = (date: Date | null) => {
    if (date && availableDates) {
      const formattedDate = format(date, 'yyyy-MM-dd', { locale: calendarLocale })
      if (availableDates[formattedDate]) {
        return findDatesBetween(availableDates[formattedDate].earliestArrivalDate, availableDates[formattedDate].latestArrivalDate)
      }
    }
    return []
  }

  const onDayHover = (date: Date | null) => {
    setHoverDates(getSchedulerRange(date))
  }

  const onDayClick = (date: Date | null) => {
    const selections = getSchedulerRange(date)
    if (selections.length && availableDates) {
      setSelectedDates(selections)
      const formattedDate = format(selections[0], 'yyyy-MM-dd', { locale: calendarLocale })
      setSelectedDeliverySchedule(availableDates[formattedDate])
    }
  }

  return (
    <Box mt={{ xs: 4, md: 5 }} onMouseLeave={() => setHoverDates([])}>
      <ErrorBoundary>
        <SectionLoaderWrapper showLoader={!selectableDates?.length && isFetchingDeliveryDetails}>
          {
            // We need to render the calendar when the container has been rendered due
            // to how react-nice-dates works: calculates its height from the container component
            isContainerRendered && (
              <Calendar
                minimumDate={minDate}
                maximumDate={maxDate}
                modifiers={calendarModifiers}
                modifiersClassNames={modifiersClassNames}
                locale={calendarLocale}
                onDayHover={onDayHover}
                onDayClick={onDayClick}
                month={currentMonth}
                onMonthChange={date => date && setCurrentMonth(date)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                touchDragEnabled={false}
              />
            )
          }
        </SectionLoaderWrapper>
        {isDateOutsideRange && (
          <Typography mt={4} fontSize={'small'} color="brand-blue">
            <FormattedMessage
              defaultMessage="Note: Delivery information is not yet available for the dates after {maxDate}"
              values={{
                maxDate: <FormattedDate value={maxDate} year="numeric" month="long" day="2-digit" />,
              }}
            />
          </Typography>
        )}
      </ErrorBoundary>
    </Box>
  )
}
