import { Box, Card, FlexBox } from '@vp/swan'
import { ShimmerTablet } from 'components/loader/shimmer/shimmer.component'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import rushLogo from '../assets/rush.svg'
import standardLogo from '../assets/standard.svg'
import classes from './processing-options-placeholder.module.scss'

const placeholders = [
  {
    id: 1,
    logo: standardLogo,
  },
  {
    id: 2,
    logo: rushLogo,
  },
]

const ProcessingOptionsPlaceholderInternal: FC<unknown> = () => {
  const intl = useIntl()
  return (
    <>
      {placeholders.map(placeholder => (
        <Card bordered key={placeholder.id} backgroundColor={'standard'} mb={3} className={classes.card}>
          <FlexBox alignItems="flex-start">
            <img
              src={placeholder.logo}
              alt={intl.formatMessage({ defaultMessage: 'placeholder postage', description: "Used for an image's alternate text when the postage is loading" })}
            />
            <Box ml={4} className="flex-1-1">
              <ShimmerTablet width={20} size="small" mb={3} />
              <ShimmerTablet width={40} size="small" />
            </Box>
          </FlexBox>
        </Card>
      ))}
    </>
  )
}

export const ProcessingOptionsPlaceholder = React.memo(ProcessingOptionsPlaceholderInternal)
