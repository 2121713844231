import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Box, Button, Callout, Card, Checkbox, FlexBox, Typography } from '@vp/swan'
import { useIsInBrowser } from 'hooks/is-in-browser.hook'
import { getMyAddress } from 'lib/localStorage/localStorageRepository'
import { useShopperId } from 'lib/user'
import { AddressFormStatus } from 'modules/confirmation/types/address.types'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { VpAddress } from 'types/vp-address-form.types'
import { noOp } from 'utilities/functions.utils'
import { AddMyAddressDialog } from './add-my-address-dialog/add-my-address-dialog.component'
import classes from './add-my-address.module.scss'

interface AddMyAddressProps {
  onAddMyAddressChanged: (myAddressId: string) => void
}
export const AddMyAddress: FC<AddMyAddressProps> = ({ onAddMyAddressChanged = () => noOp }) => {
  const [openAddressForm, setOpenAddressForm] = useState(false)
  const [myAddress, setMyAddress] = useState<VpAddress>()
  const [formStatus, setFormStatus] = useState<AddressFormStatus>(AddressFormStatus.INITIAL)
  const isInBrowser = useIsInBrowser()
  const shopperId = useShopperId() as string
  const { logWarn } = useLogger()

  useEffect(() => {
    const myAddress = getMyAddress({ isInBrowser, shopperId, logWarn })
    if (myAddress) {
      setFormStatus(AddressFormStatus.SUBMITTED)
      setMyAddress(myAddress)
    }
  }, [isInBrowser, shopperId, logWarn])

  const toggleAddressForm = () => {
    setOpenAddressForm(true)
  }
  const editShippingAddress = () => {
    setOpenAddressForm(true)
  }

  useEffect(() => {
    onAddMyAddressChanged(myAddress?.id || '')
  }, [myAddress, onAddMyAddressChanged])

  return (
    <>
      <Card bordered mb="between-subsections" className={classes.container}>
        <FlexBox alignItems="center" backgroundColor="standard">
          <Checkbox id="send-me-postcard" readOnly checked={formStatus === AddressFormStatus.SUBMITTED} onClick={toggleAddressForm} />
          <Box as="label" htmlFor="send-me-postcard" ml={4} className={classes.content}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <Typography fontSkin="body-standard-bold">
                <FormattedMessage defaultMessage="Send myself a postcard" description="To send a postcard to the user too" />
              </Typography>
              <Callout>
                <FormattedMessage defaultMessage="Recommended" description="Recommended option for user to add his address" />
              </Callout>
            </FlexBox>

            <Typography fontSkin="body-small" textColor="subtle">
              {formStatus !== AddressFormStatus.SUBMITTED && <FormattedMessage defaultMessage="Enter the address where you want to receive the postcard." />}
              {formStatus === AddressFormStatus.SUBMITTED && (
                <Button
                  data-section="Mailing List Page:Text Link"
                  data-position="0"
                  data-translation="Edit my mailing address"
                  style={{ textDecoration: 'underline' }}
                  onClick={editShippingAddress}
                  skin="unstyled"
                >
                  <FormattedMessage defaultMessage="Edit my mailing address" description="Button label to edit the address of the user" />
                </Button>
              )}
            </Typography>
          </Box>
        </FlexBox>
      </Card>
      <AddMyAddressDialog
        openAddressForm={openAddressForm}
        setOpenAddressForm={setOpenAddressForm}
        formStatus={formStatus}
        setFormStatus={setFormStatus}
        myAddress={myAddress}
        setMyAddress={setMyAddress}
      />
    </>
  )
}
