import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { AlertBox, Button, Column, GridContainer, H1, H2, Row, Typography } from '@vp/swan'
import { ExceededAddressesModal } from 'components/exceeded-addresses-modal/exceeded-addresses-modal'
import { FullPageLoader, SectionLoaderWrapper } from 'components/loader/loader.component'
import { WorkIdPreview } from 'components/work-id-preview/work-id-preview.component'
import { useMailingListIdQueryParam, usePurchaseListWorkIdQueryParam, useSkipMSValidation } from 'contexts/query-param.context'
import { useIsInBrowser } from 'hooks/is-in-browser.hook'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PageLayout } from 'layouts/page/page.layout'
import { useMailingListForId, useValidateMailingListPostcard } from 'lib/address-list'
import { withErrorBoundary } from 'lib/errors'
import { cleanMyAddress } from 'lib/localStorage/localStorageRepository'
import { useAddToCart } from 'lib/mailing-svc-api'
import { TransformedPostageDate } from 'lib/mailing-svc-api/mailing-service-api.types'
import { useDeliveryPossibilities } from 'lib/mailing-svc-api/mailing-service.hooks'
import { ATTRIBUTE_KEYS, PricingSummary, useProductInfo, useProductInfoUserSelection } from 'lib/product-info'
import { useProductInfoPricingSummary, useProductInfoQtySelection, useProductInfoUpdate } from 'lib/product-info/product-info.context'
import { usePurchaseListWorkEntity } from 'lib/purchase-list/purchase-list.hooks'
import { useSiteFlowConfirmation } from 'lib/site-flow'
import { PageName, useEventTracking } from 'lib/telemetry'
import { PageNameForLogging, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useToast } from 'lib/toast/toast.context'
import { useShopperId } from 'lib/user'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MailingListDetailedEntity } from 'types/mailing-list.types'
import { PurchaseListWokEntityMetadata } from 'types/purchase-list.types'
import { windowOpen } from 'utilities/navigate.utils'
import { useConfirmation } from '../../contexts/confirmation.context'
import { AddToCartRequest, DeliveryDates } from '../../types/confirmation.types'
import { AddMyAddress } from '../add-my-address/add-my-address.component'
import { CustomerMovement } from '../customer-movement/customer-movement.component'
import { ProcessingOptions } from '../processing-options/processing-options.component'
import { ReviewRecipients } from '../review-recipients/review-recipients.component'
import classes from './confirmation-container.module.scss'

const ConfirmationContainerInternal: FC<unknown> = () => {
  const mailingListId = useMailingListIdQueryParam()
  const purchaseListWorkId = usePurchaseListWorkIdQueryParam()
  const unknownErrorToast = useToastSomethingWentWrong()
  const productInfoQueryResult = useProductInfo()
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [cartWorkId, setCartWorkId] = useState<string>()
  const [confirmedDeliverySchedule, setConfirmedDeliverySchedule] = useState<TransformedPostageDate | null>(null)
  const { userSelection } = useProductInfoUserSelection()
  const { selectedQty } = useProductInfoQtySelection()
  const updateSelection = useProductInfoUpdate()
  const { setShowScheduler } = useConfirmation()
  const fireTracking = useEventTracking()
  const [myAddressId, setMyAddressId] = useState<string>('')
  const { data: mailingList, isFetching: isFetchingMailingList, isError: isErrorMailingList } = useMailingListForId(mailingListId)
  const { data: purchaseListWorkEntity, isFetching: isFetchingPurchaseList, isError: isErrorPurchaseList } = usePurchaseListWorkEntity(purchaseListWorkId)
  const skipMSValidation = useSkipMSValidation()
  const { logError, logWarn } = useLogger()

  const {
    data: validatedMailingListResult,
    isError: isErrorValidatingMailingList,
    isFetching: isValidatingMailingList,
  } = useValidateMailingListPostcard(skipMSValidation ? {} : { mailingListId })

  const addressListLength = calculateAddressListLength({ mailingList, purchaseListWorkEntity, myAddressId })
  const isInBrowser = useIsInBrowser()
  const shopperId = useShopperId() as string
  const price = useProductInfoPricingSummary()

  const {
    data: deliveryDetails,
    isIdle: isIdleDeliveryDetails,
    isLoading: isLoadingDeliveryDetails,
    isError: isErrorDeliveryDetails,
  } = useDeliveryPossibilities(addressListLength)

  const { data: siteFlowNextStep, isLoading: isLoadingSiteFlowNextStep, isError: isErrorSiteFlow } = useSiteFlowConfirmation(cartWorkId)

  const {
    mutationResult: { mutateAsync: addToCart, isError: isErrorAddToCart },
  } = useAddToCart()

  const intl = useIntl()
  const toast = useToast()
  const trackingProductPageName = useTrackingProductPageName(PageName.CONFIRMATION_PAGE)

  useEffect(() => {
    if (isErrorAddToCart) {
      unknownErrorToast()
    }
  }, [isErrorAddToCart, unknownErrorToast])

  useEffect(() => {
    if (addressListLength && selectedQty !== addressListLength) {
      updateSelection({
        qty: addressListLength,
      })
    }
  }, [intl, addressListLength, selectedQty, toast, updateSelection])

  // siteflow will have data only when add to cart API return response with workId
  useEffect(() => {
    if (siteFlowNextStep) {
      windowOpen(siteFlowNextStep.url)
    }
  }, [siteFlowNextStep])

  useEffect(() => {
    if (isErrorSiteFlow) {
      unknownErrorToast()
      logWarn('Add to Cart: Next step in flow unavailable', { contextData: { mailingListId } })
    }
  }, [isErrorSiteFlow, mailingListId, unknownErrorToast, logWarn])

  useEffect(() => {
    if (isErrorDeliveryDetails) {
      unknownErrorToast()
      logWarn('Confirmation delivery possibilities unavailable', { contextData: { mailingListId } })
    }
  }, [isErrorDeliveryDetails, mailingListId, unknownErrorToast, logWarn])

  useEffect(() => {
    if (productInfoQueryResult?.data?.standardConfigurator?.selections?.completeProduct === undefined && productInfoQueryResult?.isFetched) {
      logError('Error completing product', { contextData: { mailingListId, responseData: productInfoQueryResult?.data } })

      toast({
        type: 'ADD',
        config: {
          skin: 'error',
          content: intl.formatMessage({
            defaultMessage: "We're having difficulties loading this page. Contact our customer support team to solve this issue.",
          }),
          duration: 15000,
        },
      })
    }
  }, [productInfoQueryResult])

  const onAddMyAddressChangedHandler = (myAddressId: string) => {
    setMyAddressId(myAddressId)
  }

  const onNext = () => {
    if (confirmedDeliverySchedule) {
      if (deliveryDetails) {
        const deliveryDates: DeliveryDates = {
          earliestArrivalDate: confirmedDeliverySchedule.earliestArrivalDate,
          latestArrivalDate: confirmedDeliverySchedule.latestArrivalDate,
          deliveryPossibility: deliveryDetails?.deliveryPossibilities.filter(u => u.date === confirmedDeliverySchedule.dispatchDate)[0],
        }
        const request: AddToCartRequest = {
          deliveryDates,
          mailingServicesMerchandisingSelections: {
            'Address Service Option': userSelection[ATTRIBUTE_KEYS.ADDRESS_SVC_OPTION],
            Processing: userSelection[ATTRIBUTE_KEYS.PROCESSING],
          },
        }

        fireTrackingAddToCart()
        fireTrackingSelectedPostageOption()
        if (myAddressId) {
          fireTrackingSendMySelfAPostcard()
        }

        setIsAddingToCart(true)
        addToCart({ request, mailingListId, purchaseListWorkId, myAddressId })
          .then(res => {
            setCartWorkId(res.workId)
            cleanMyAddress({ isInBrowser, shopperId, logWarn })
          })
          .catch(() => {
            unknownErrorToast()
            setIsAddingToCart(false)
            logError('Error occurred while adding to cart', {
              contextData: {
                mailingListId,
                cartWorkId,
                purchaseListWorkId,
                confirmedDeliverySchedule,
                deliveryDetails,
                request,
              },
            })
          })
      } else {
        unknownErrorToast()
        logWarn('Delivery details are not available', { contextData: { mailingListId } })
      }
    } else {
      toast({
        type: 'ADD',
        config: {
          skin: 'error',
          content: intl.formatMessage({
            defaultMessage: 'Please select your processing option and schedule your delivery.',
            description: 'If no delivery schedule is selected by the user',
          }),
        },
      })
      setShowScheduler(true)
    }
  }

  const fireTrackingSelectedPostageOption = () => {
    const postageTrackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: userSelection[ATTRIBUTE_KEYS.PROCESSING],
      pageName: trackingProductPageName,
    }
    fireTracking(TrackingEventType.POSTAGE_OPTION_SELECTED, postageTrackingData)
  }

  const fireTrackingAddToCart = () => {
    const trackingData = {
      category: TrackingCategoryType.ECOMMERCE,
      label: TrackingLabelType.PRODUCT_ADDED,
      pageName: trackingProductPageName,
      quantity: 1,
      sales_quantity: myAddressId ? addressListLength + 1 : addressListLength,
      price: price?.unitListPrice,
      listPrice: price?.totalListPrice,
      discount: price?.unitDiscountedPrice,
    }
    fireTracking(TrackingEventType.PRODUCT_ADDED, trackingData)
  }

  const fireTrackingSendMySelfAPostcard = () => {
    const trackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TrackingLabelType.SEND_POSTCARD,
      pageName: trackingProductPageName,
    }

    fireTracking(TrackingEventType.OPTION_SELECTED, trackingData)
  }

  const nextButton = (
    <Button
      onClick={onNext}
      skin="primary"
      disabled={
        isErrorMailingList ||
        isIdleDeliveryDetails ||
        isLoadingDeliveryDetails ||
        isAddingToCart ||
        isLoadingSiteFlowNextStep ||
        isErrorPurchaseList ||
        isErrorValidatingMailingList
      }
    >
      <FormattedMessage defaultMessage="Add to cart" description="To add the mailing list to the cart and move to the next page" />
    </Button>
  )

  return (
    <PageLayout onBackConfirm nextElem={nextButton} pageName={trackingProductPageName} pageNameForLogging={PageNameForLogging.CONFIRMATION_PAGE}>
      {(isFetchingMailingList || isAddingToCart || isLoadingSiteFlowNextStep || isFetchingPurchaseList || isValidatingMailingList) && <FullPageLoader />}
      {isErrorMailingList && (
        <AlertBox skin="error" my={6} toast>
          <Typography textAlign="center">
            <FormattedMessage defaultMessage="Unexpected error! Looks like the mailing list doesn't exist." />
          </Typography>
        </AlertBox>
      )}
      {isErrorPurchaseList && (
        <AlertBox skin="error" my={6} toast>
          <Typography textAlign="center">
            <FormattedMessage defaultMessage="Unexpected error! Looks like the purchase list information doesn't exist." />
          </Typography>
        </AlertBox>
      )}
      {isErrorValidatingMailingList && (
        <AlertBox skin="error" my={6} toast>
          <Typography textAlign="center">
            <FormattedMessage defaultMessage="Unexpected error! We couldn't validate your list. Please refresh the page. If the problem persists, reach our support team" />
          </Typography>
        </AlertBox>
      )}
      {!isErrorMailingList && !isErrorPurchaseList && !isErrorValidatingMailingList && (
        <>
          {validatedMailingListResult && !validatedMailingListResult?.isCompatible && (
            <ExceededAddressesModal
              showDownloadMailingListButton={true}
              showEditRecipientsButton={false}
              invalidRecipients={validatedMailingListResult?.numberOfIncompatibleAddresses ?? 0}
              totalRecipients={validatedMailingListResult?.totalAddresses ?? 0}
              mailingListId={mailingListId}
              pageName={trackingProductPageName}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              continueAction={() => {}}
            />
          )}

          <GridContainer pt={{ xs: 8, sm: 9, md: 10 }} mb={{ xs: 11, md: 12 }}>
            <Row sticky>
              <Column span={6} spanMd={6} spanSm={12}>
                <H1 fontSkin="title-headline" marginBottom={'between-subsections'}>
                  <FormattedMessage defaultMessage="How do you want to send your postcards?" />
                </H1>
                <H2 fontSkin="title-subsection">
                  <FormattedMessage defaultMessage="Mailing List" description="Heading for the mailing list" />
                </H2>
                <ReviewRecipients numberOfAddresses={addressListLength} isMaiLingList={!!mailingList} />
                <AddMyAddress onAddMyAddressChanged={onAddMyAddressChangedHandler} />
                <SectionLoaderWrapper showLoader={productInfoQueryResult?.isFetching}>
                  <ProcessingOptions recipientsCount={addressListLength} setConfirmedDeliverySchedule={setConfirmedDeliverySchedule} />
                  <CustomerMovement />
                  <PricingSummary recipientsCount={addressListLength} confirmedDeliverySchedule={confirmedDeliverySchedule} />
                </SectionLoaderWrapper>
              </Column>
              <Column sticky span={5} spanMd={6} offset={1} offsetMd={0} className={classes.reviewImgContainer}>
                <WorkIdPreview purpose="merchandising.cross-sell.tile" />
              </Column>
            </Row>
          </GridContainer>
        </>
      )}
    </PageLayout>
  )
}

export const ConfirmationContainer = withErrorBoundary(ConfirmationContainerInternal)

function calculateAddressListLength(params: {
  mailingList: MailingListDetailedEntity | undefined
  purchaseListWorkEntity: PurchaseListWokEntityMetadata | undefined
  myAddressId: string | undefined
}) {
  const { mailingList, purchaseListWorkEntity, myAddressId } = params
  let numberOfRecipients = mailingList?.quantity ?? purchaseListWorkEntity?.finalizeData.recipientCount ?? 0
  if (myAddressId) {
    numberOfRecipients += 1
  }

  return numberOfRecipients
}
