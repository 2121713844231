import { H2 } from '@vp/swan'
import { withErrorBoundary } from 'lib/errors'
import { ATTRIBUTE_KEYS, StandardConfiguratorAttribute, useProductInfoConfigAttribute } from 'lib/product-info'
import { ProductAttributeSelector } from 'lib/product-info/get-quote/product-attribute-selector.component'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { getConfiguratorAttribute } from 'utilities/attributes.utils'
import { ADDRESS_SERVICE_OPTIONS } from '../../types/confirmation.types'

function useTransformedMessages(addressServiceConfig?: StandardConfiguratorAttribute) {
  const intl = useIntl()
  const AddressSvcMessages: Record<string, string> = {
    [ADDRESS_SERVICE_OPTIONS.CURRENT_RESIDENT]: intl.formatMessage({ defaultMessage: 'Deliver to current resident' }),
    [ADDRESS_SERVICE_OPTIONS.DISCARD]: intl.formatMessage({ defaultMessage: 'Discard it' }),
    [ADDRESS_SERVICE_OPTIONS.FORWARD]: intl.formatMessage({ defaultMessage: 'Forward it to their new address' }),
    [ADDRESS_SERVICE_OPTIONS.NOTIFY_AND_RETURN]: intl.formatMessage({ defaultMessage: 'Notify and return it to me' }),
  }
  addressServiceConfig?.options.forEach(option => {
    option.name = AddressSvcMessages[option.key] || option.name
  })
  return addressServiceConfig
}

const CustomerMovementInternal: FC<unknown> = () => {
  const attributes = useProductInfoConfigAttribute()
  const addressServiceConfig = useTransformedMessages(getConfiguratorAttribute(ATTRIBUTE_KEYS.ADDRESS_SVC_OPTION, attributes))
  const intl = useIntl()
  const recipientMovedMessage = intl.formatMessage({
    defaultMessage: 'What if a recipient moved?',
    description: 'Dropdown shown for the action to be token if a customer moved',
  })
  return (
    <>
      <H2 fontSkin="title-subsection" id={recipientMovedMessage}>
        {recipientMovedMessage}
      </H2>
      <ProductAttributeSelector withLabel={false} attribute={addressServiceConfig} />
    </>
  )
}

export const CustomerMovement = withErrorBoundary(CustomerMovementInternal)
