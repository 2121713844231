import { StandardConfiguratorAttribute } from 'lib/product-info'
import { POSTAGE_PROCESSING_OPTIONS } from 'modules/confirmation/types/confirmation.types'

export const transformedProcessingOptions = (processingConfig?: StandardConfiguratorAttribute) => {
  const standardOptionIdx = processingConfig?.options.findIndex(u => u.key === POSTAGE_PROCESSING_OPTIONS.STANDARD)
  if (standardOptionIdx !== undefined) {
    const standardOption = processingConfig?.options.splice(standardOptionIdx, 1)
    if (standardOption) processingConfig?.options.unshift(standardOption[0])
  }
  return processingConfig?.options
}
